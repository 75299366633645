import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "D:\\Workspaces\\StackRover\\horizon-welfare\\node_modules\\next-auth\\react.js");
;
import(/* webpackMode: "eager" */ "D:\\Workspaces\\StackRover\\horizon-welfare\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\app\\\\layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"plusJakartaSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "D:\\Workspaces\\StackRover\\horizon-welfare\\node_modules\\react-hot-toast\\dist\\index.mjs");
;
import(/* webpackMode: "eager" */ "D:\\Workspaces\\StackRover\\horizon-welfare\\src\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Workspaces\\StackRover\\horizon-welfare\\src\\components\\custom\\Children.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "D:\\Workspaces\\StackRover\\horizon-welfare\\src\\components\\ui\\toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressProvider"] */ "D:\\Workspaces\\StackRover\\horizon-welfare\\src\\providers\\ProgressProvider.tsx");
